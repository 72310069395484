import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import logoDesktop from "../../assets/images/icon/logo-desktop.png";
import logoDarkDesktop from "../../assets/images/icon/logo-dark-desktop.png";
import logoMobile from "../../assets/images/icon/logo-mobile.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import facebook from "../../assets/images/icon/facebook.png";
import linkIn from "../../assets/images/icon/linkIn.png";
import youtube from "../../assets/images/icon/youtube.png";
import twitter from "../../assets/images/icon/twitter.png";
import {
  AppBar,
  Box,
  Collapse,
  createTheme,
  IconButton,
  Slide,
  ThemeProvider,
  Toolbar,
} from "@mui/material";
import { useEffect, useState } from "react";

const ContactUsButton = (props: { class: string; onClick: () => void }) => {
  return (
    <>
      <Link
        to="/contact-us"
        className={`${props.class} items-center justify-center relative bg-linear-orange p-2 h-[42px] tablet:h-[52px] text-center text-white-highlight font-[500] text-lg tablet:text-xl rounded-lg shadow-button`}
        onClick={props.onClick}
      >
        Contact Us
      </Link>
    </>
  );
};
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});
export default function Navbar() {
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [textNavbar, setTextNavbar] = useState<string>("text-white-primary");
  const [bgNavbar, setBGNavbar] = useState<string>("transparent");
  const [textService, setTextService] = useState<string>("text-accent-orange");
  const [bgServiceMenu, setBGServiceMenu] = useState<string>("bg-black/90");
  const [darkLogo, setDarkLogo] = useState<boolean>(false);
  const location = useLocation();
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const handleCloseMenu = () => {
    setMobileOpen(false);
    setOpenMenu(false);
  };
  useEffect(() => {
    if (location.pathname === "/" || location.pathname === "/education") {
      setBGNavbar("transparent");
      setTextNavbar("text-white-primary");
      setTextService("text-accent-orange");
      setBGServiceMenu("bg-black/90");
      setDarkLogo(false);
    } else {
      setTextNavbar("text-base-black");
      setBGNavbar("rgba(255, 255, 255, 0.15)");
      setTextService("text-base-black");
      setBGServiceMenu("bg-white-pure/90");
      setDarkLogo(true);
    }
  }, [location]);
  useEffect(() => {
    const content = document.getElementById("content");
    const contactUs = document.getElementById("contact-us");
    const footer = document.getElementById("footer");
    if (content && contactUs && footer) {
      if (mobileOpen) {
        content.style.height = "100vh";
        content.style.overflow = "hidden";
        footer.style.display = "none";
        contactUs.style.display = "none";
      } else {
        content.style.height = "100%";
        content.style.overflow = "auto";
        footer.style.display = "flex";
        contactUs.style.display = "flex";

        setOpenMenu(false);
      }
    }
  }, [mobileOpen]);

  useEffect(() => {
    window.addEventListener("resize", (e: any) => {
      if (e.target.outerWidth > 767) {
        setMobileOpen(false);
        setOpenMenu(false);
      }
    });
  });

  return (
    <ThemeProvider theme={theme}>
      <div className="flex text-black">
        <AppBar
          sx={{
            border: "none",
            backgroundColor: bgNavbar,
            boxShadow: "none",
          }}
          component="nav"
          position="static"
        >
          <Toolbar
            sx={{
              minHeight: { xs: "60px !important", sm: "80px !important" },
              background: {
                xs: mobileOpen ? "rgb(52 54 75)" : "",
                md: bgNavbar,
              },
              transition: "400ms linear",
            }}
          >
            <div className="flex flex-row items-center justify-between w-full h-full ">
              <NavLink
                to={"/"}
                className="flex tablet:hidden flex-row items-center  mobile:w-[120px] mobile:h-50px tablet:w-[140px] tablet:h-[60px]"
              >
                <img src={logoMobile} alt="logo" className="scale-75" />
              </NavLink>
              <NavLink
                to={"/"}
                className="hidden tablet:flex flex-row items-center  p-4 rounded-tr-[20px] rounded-br-[20px] mobile:w-[120px] mobile:h-50px tablet:w-[140px] tablet:h-[60px]"
              >
                <img
                  src={darkLogo ? logoDarkDesktop : logoDesktop}
                  alt="logo"
                  className=" scale-150"
                />
              </NavLink>
              <ul
                className={`hidden tablet:flex list-none justify-around items-center text-xl space-x-12 ${textNavbar}`}
              >
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li id="linkEducation" className="group">
                  <Link to="/education">
                    Services{" "}
                    <KeyboardArrowDownIcon className=" origin-center group-hover:rotate-180 transition-test" />
                  </Link>
                  <div
                    className={`hidden group-hover:flex transition-opacity ${textService} ${bgServiceMenu} absolute w-52 z-50 p-4  flex-col gap-2`}
                  >
                    <Link to="/innovation-services">Innovation Services</Link>
                    <Link to="/education">Education</Link>
                    <Link to="/people">People</Link>
                    <Link to="/opportunity">Opportunity</Link>
                  </div>
                </li>
                <li>
                  <Link to="/blogs">Blogs</Link>
                </li>
                <li>
                  <ContactUsButton
                    class={"hidden tablet:flex"}
                    onClick={handleCloseMenu}
                  />
                </li>
              </ul>
              <div className="tablet:hidden flex gap-4">
                <ContactUsButton
                  class="flex tablet:hidden"
                  onClick={handleCloseMenu}
                />
                <IconButton
                  className=""
                  onClick={handleDrawerToggle}
                  sx={{
                    transform: !mobileOpen ? "rotate(180deg)" : "",
                    transition: "600ms",
                  }}
                >
                  {!mobileOpen ? (
                    <MenuIcon
                      sx={{
                        color: "white",
                      }}
                    />
                  ) : (
                    <CloseIcon
                      sx={{
                        color: "white",
                      }}
                    />
                  )}
                </IconButton>
              </div>
            </div>
          </Toolbar>
          <Slide
            direction="up"
            timeout={{
              appear: 600,
              enter: 600,
              exit: 600,
            }}
            in={mobileOpen}
            mountOnEnter
            unmountOnExit
            style={{
              position: "absolute",
              height: "100vh",
              marginTop: "60px",
            }}
          >
            <Box
              component="div"
              sx={{
                background: "#3D3F55",
                height: "100%",
                width: "100%",
                // overflow: "scroll",
                padding: "70px 2rem",
                paddingTop: "20px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                zIndex: "100",
              }}
            >
              <Link
                to="/home"
                className="text-xl px-8 py-4 text-center"
                onClick={handleCloseMenu}
              >
                Home
              </Link>
              <div className="pl-24 pr-8 py-4 flex justify-between items-center">
                <Link
                  to="/education"
                  className="text-xl  "
                  onClick={handleCloseMenu}
                >
                  Services{" "}
                </Link>
                <IconButton
                  sx={{
                    marginLeft: "32px",
                    transform: openMenu ? "rotate(180deg)" : "",
                    transition: "400ms",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenMenu((prev) => !prev);
                  }}
                >
                  <KeyboardArrowDownIcon sx={{ color: "white" }} />
                </IconButton>
              </div>
              <div>
                <Collapse
                  in={openMenu}
                  timeout={{
                    appear: 700,
                    enter: 700,
                    exit: 700,
                  }}
                  orientation="vertical"
                  mountOnEnter
                  unmountOnExit
                >
                  {" "}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      transition: "1s linear",
                      color: "#F89838",
                    }}
                  >
                    <Link
                      to="/innovation-services"
                      className="text-xl px-8 py-4 text-center"
                      onClick={handleCloseMenu}
                    >
                      Innovation Services
                    </Link>
                    <Link
                      to="/education"
                      className="text-xl px-8 py-4 text-center"
                      onClick={handleCloseMenu}
                    >
                      Education
                    </Link>{" "}
                    <Link
                      to="/education#connext"
                      className="text-xl px-8 py-4 text-center"
                      onClick={handleCloseMenu}
                    >
                      Connext
                    </Link>{" "}
                    <Link
                      to="/education#community"
                      className="text-xl px-8 py-4 text-center"
                      onClick={handleCloseMenu}
                    >
                      Community & Ecosystem
                    </Link>{" "}
                    <Link
                      to="/education#accelerator"
                      className="text-xl px-8 py-4 text-center"
                      onClick={handleCloseMenu}
                    >
                      Thailand Accelerator
                    </Link>{" "}
                  </Box>
                </Collapse>
              </div>

              <Link
                to="/blogs"
                className="text-xl px-8 py-4 text-center"
                onClick={handleCloseMenu}
              >
                Blogs
              </Link>
              <Link
                to="/about-us"
                className="text-xl px-8 py-4 text-center"
                onClick={handleCloseMenu}
              >
                About Us
              </Link>
              <div className="flex gap-4 items-center px-8 py-4">
                <Link
                  to="https://www.facebook.com/techsauceTH/"
                  onClick={handleCloseMenu}
                >
                  <img src={facebook} alt="Facebook" />
                </Link>
                <Link
                  to="https://www.linkedin.com/company/techsauce"
                  onClick={handleCloseMenu}
                >
                  <img src={twitter} alt="LinkIn" />
                </Link>
                <Link
                  to="https://www.linkedin.com/company/techsauce"
                  onClick={handleCloseMenu}
                >
                  <img src={linkIn} alt="LinkIn" />
                </Link>
                <Link
                  to="https://www.youtube.com/@TechsauceThailand"
                  onClick={handleCloseMenu}
                >
                  <img src={youtube} alt="Youtube" />
                </Link>
              </div>
            </Box>
          </Slide>
        </AppBar>
      </div>
    </ThemeProvider>
  );
}
