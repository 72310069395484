import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import ContactUsComponent from "../components/contact-us/contact-us-component";
import FooterComponent from "../components/footer/footer-component";

import Navbar from "../components/navbar/navbar";

export default function Root() {
  const location = useLocation();
  const [showContact, setShowContact] = useState<boolean>(true);
  const [showScrollUpBtn, setShowScrollUpBtn] = useState<boolean>(false);
  useEffect(() => {
    if (
      location.pathname === "/about-us" ||
      location.pathname === "/blogs" ||
      location.pathname === "/contact-us"
    ) {
      setShowContact(false);
    } else {
      setShowContact(true);
    }
  }, [location]);
  const handleNavigation = (e: any) => {
    if (window.scrollY > 1200) {
      setShowScrollUpBtn(true);
    } else {
      setShowScrollUpBtn(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, []);

  return (
    <>
      <Navbar />
      <div id="content" className="-mt-[60px] tablet:-mt-[120px] ">
        <Outlet />
      </div>

      {showContact && (
        <button
          type="button"
          className={`${
            showScrollUpBtn
              ? "hidden tablet:block tablet:fixed  animate-fadeIn"
              : "hidden"
          }  text-white-pure text-[16px] font-bold right-5 bottom-5 z-50 bg-accent-brand p-4 h-12 w-12 rounded-[50%]`}
          onClick={() => {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
          }}
        >
          ^
        </button>
      )}

      {showContact && <ContactUsComponent />}
      <FooterComponent />
    </>
  );
}
