import React, { Suspense } from "react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./App.css";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import Root from "./routes/root";
import ErrorPage from "./error-page";
import { HelmetProvider } from "react-helmet-async";
const HomePage = React.lazy(() => import("./page/home-page"));
const EducationPage = React.lazy(() => import("./page/education-page"));
const BlogPage = React.lazy(() => import("./page/blogs-page"));
const ContactUsPage = React.lazy(() => import("./page/contact-us-page"));
const AboutUsPage = React.lazy(() => import("./page/about-us-page"));
const InnovationServicesPage = React.lazy(
  () => import("./page/innovation-service-page")
);
const PeoplePage = React.lazy(() => import("./page/people-page"));
const PrivacyPage = React.lazy(() => import("./page/privacy-page"));
const OpportunityPage = React.lazy(() => import("./page/opportunity-page"));
const ContentPage = React.lazy(() => import("./page/content-page"));
const Loading = () => {
  return <div>Loading...</div>;
};
const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: (
          <Suspense fallback={<Loading />}>
            <HomePage />
          </Suspense>
        ),
      },
      {
        path: "/education",
        element: (
          <Suspense fallback={<Loading />}>
            <EducationPage />
          </Suspense>
        ),
      },
      {
        path: "/blogs",
        element: (
          <Suspense fallback={<Loading />}>
            <BlogPage />
          </Suspense>
        ),
      },
      {
        path: "/contact-us",
        element: (
          <Suspense fallback={<Loading />}>
            <ContactUsPage />
          </Suspense>
        ),
      },

      {
        path: "/about-us",
        element: (
          <Suspense fallback={<Loading />}>
            <AboutUsPage />
          </Suspense>
        ),
      },

      {
        path: "/innovation-services",
        element: (
          <Suspense fallback={<Loading />}>
            <InnovationServicesPage />
          </Suspense>
        ),
      },
      {
        path: "/people",
        element: (
          <Suspense fallback={<Loading />}>
            <PeoplePage />
          </Suspense>
        ),
      },
      {
        path: "/opportunity",
        element: (
          <Suspense fallback={<Loading />}>
            <OpportunityPage />
          </Suspense>
        ),
      },
      {
        path: "/privacy",
        element: (
          <Suspense fallback={<Loading />}>
            <PrivacyPage />
          </Suspense>
        ),
      },
      {
        path: "/content",
        element: (
          <Suspense fallback={<Loading />}>
            <ContentPage />
          </Suspense>
        ),
      },
      { path: "/home", element: <Navigate to="/" replace /> },
    ],
  },
]);
function App() {
  return (
    // <HelmetProvider>
    <div className="App">
      <RouterProvider router={router} />
    </div>
    // </HelmetProvider>
  );
}

export default App;
