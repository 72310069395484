import React from "react";
import { useForm, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { MenuItem, Select } from "@mui/material";
import facebook from "../../assets/images/icon/facebook.png";
import linkIn from "../../assets/images/icon/linkIn.png";
import twitter from "../../assets/images/icon/twitter.png";
import contactCover from "../../assets/images/contact-us-page/contact-cover.png";
import { Link } from "react-router-dom";
import { Widget } from "@typeform/embed-react";

type FormValues = {
  fullname?: string;
  email?: string;
  companyName?: string;
  services?: string;
  message?: string;
};
export default function ContactUsComponent() {
  const { handleSubmit, control, reset } = useForm<FormValues>({
    mode: "onChange",
  });

  return (
    <div
      id="contact-us"
      className=" bg-accent-darkBlue text-white-pure flex justify-center items-center  min-h-[695px]"
    >
      <div className="bg-contact flex justify-center min-h-[695px] w-full desktop:w-[1280px]">
        <div className="flex flex-col items-center justify-center p-8 pt-16 tablet:hidden">
          <div className="flex justify-center ">
            <img src={contactCover} alt="cover" />
          </div>
          <div className="mt-8 flex flex-col items-center   h-full w-full rounded-xl">
            <em className="text-transparent bg-clip-text bg-linear-orange text-xl font-bold uppercase">
              contact us
            </em>
            <h2 className=" text-white-highlight text-base font-bold mt-4 text-center capitalize">
              Let’s create something awesome
            </h2>
            <Widget id="PjpD1rrE" className="mt-8 w-full h-[400px]" />

            {/* <form
              onSubmit={handleSubmit((data) => {
                console.log(data);
                reset();
              })}
              className="mt-8"
            >
              <Controller
                control={control}
                name="fullname"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <TextField
                    placeholder="Full Name"
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: "white",
                        height: "50px",
                        borderRadius: "12px",
                        border: "1px #6D6E764D solid",
                      },
                    }}
                    onChange={onChange}
                    value={value}
                  />
                )}
                rules={{ required: true }}
                defaultValue=""
              />
              <Controller
                control={control}
                name="email"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <TextField
                    placeholder="Email"
                    sx={{
                      marginTop: "16px",
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: "white",
                        height: "50px",
                        borderRadius: "12px",
                        border: "1px #6D6E764D solid",
                      },
                    }}
                    onChange={onChange}
                    value={value}
                  />
                )}
                defaultValue=""
                rules={{ required: true }}
              />
              <Controller
                control={control}
                name="companyName"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <TextField
                    placeholder="Company Name"
                    sx={{
                      marginTop: "16px",
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: "white",
                        height: "50px",
                        borderRadius: "12px",
                        border: "1px #6D6E764D solid",
                      },
                    }}
                    onChange={onChange}
                    value={value}
                  />
                )}
                defaultValue=""
                rules={{ required: true }}
              />

              <Controller
                control={control}
                name="services"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Select
                    id="simple-select"
                    value={value}
                    onChange={onChange}
                    placeholder="Select Services"
                    displayEmpty
                    sx={{
                      background: "white",
                      borderRadius: "12px",
                      height: "50px",
                      width: "100%",
                      marginTop: "1rem",
                    }}
                  >
                    <MenuItem disabled value="">
                      <em className="text-[#a4a4a4]">Selected Services</em>
                    </MenuItem>
                    <MenuItem value={"10"}>Ten</MenuItem>
                    <MenuItem value={"20"}>Twenty</MenuItem>
                    <MenuItem value={"30"}>Thirty</MenuItem>
                  </Select>
                )}
                defaultValue=""
                rules={{ required: true }}
              />
              <Controller
                control={control}
                name="message"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <TextField
                    placeholder="Message"
                    multiline
                    rows={3}
                    sx={{
                      marginTop: "16px",
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: "white",
                        borderRadius: "12px",
                        border: "1px #6D6E764D solid",
                      },
                    }}
                    onChange={onChange}
                    value={value}
                  />
                )}
                defaultValue=""
                rules={{ required: true }}
              />

              <button
                type="submit"
                className={`mt-8 items-center justify-center relative bg-linear-orange p-2 h-[42px] tablet:h-[52px] text-center text-white-highlight font-[500] text-lg tablet:text-xl rounded-lg shadow-button w-full`}
              >
                Send Message
              </button>
            </form> */}
          </div>
        </div>
        <div className="hidden tablet:flex justify-between w-full ">
          <div className="flex justify-end items-end w-1/2 p-8 pr-0 laptop:pl-28 desktop:pl-32 pb-12">
            <div className="flex justify-center gap-2 laptop:gap-6 bg-white-pure/25 text-white-primary  backdrop-blur-lg px-4 laptop:px-6 py-8 rounded-xl">
              <div className="w-1/2 ">
                <h4 className=" text-white-primary text-lg font-[400] p-2 pl-0 border-b border-white-pure">
                  Working hours
                </h4>
                <p className="mt-2">
                  Monday to Friday <br />
                  <b className="text-white-pure">10.30 AM to 6.00 PM</b> <br />
                  Our support team will get back to you as soon as possible.
                </p>
              </div>
              <div className="w-1/2 leading-[24px]">
                <h4 className=" text-white-primary text-lg font-[400]  p-2 pl-0 border-b border-white-pure">
                  Contact us
                </h4>{" "}
                <p className="mt-2">
                  02-001-5375
                  <br />
                  <b className="text-white-pure">06-4658-9500</b>
                  <br />
                  contact@techsauce.co{" "}
                </p>
                <div className="flex gap-4 items-center  py-4 ">
                  <Link to="/">
                    <img src={facebook} alt="Facebook" />
                  </Link>

                  <Link to="/">
                    <img src={twitter} alt="Twitter" />
                  </Link>
                  <Link to="/">
                    <img src={linkIn} alt="LinkIn" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center w-1/2 p-8 pb-12 ">
            <div className="flex flex-col items-center bg-linear-background-contact backdrop-blur-[20px] p-8 h-full w-full rounded-xl">
              <em className="text-transparent bg-clip-text bg-linear-orange text-2xl font-bold uppercase">
                contact us
              </em>
              <h2 className=" text-white-highlight text-xl font-bold mt-4 text-center capitalize">
                Let’s create something awesome
              </h2>
              <Widget id="PjpD1rrE" className="mt-8 w-full h-[100%]" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
