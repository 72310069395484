import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import logo from "../../assets/images/icon/logo-desktop.png";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import facebook from "../../assets/images/icon/facebook.png";
import linkIn from "../../assets/images/icon/linkIn.png";
import twitter from "../../assets/images/icon/twitter.png";
import youtube from "../../assets/images/icon/youtube.png";
import { Box, Collapse, IconButton } from "@mui/material";
export default function FooterComponent() {
  const [openMenuName, setOpenMenuName] = useState<string>("");
  useEffect(() => {
    window.addEventListener("resize", (e: any) => {
      if (e.target.outerWidth > 767) {
        setOpenMenuName("");
      }
    });
  });
  return (
    <footer
      id="footer"
      className="p-8 flex tablet:justify-center bg-linear-footer text-white-pure"
    >
      {/* mobile */}
      <div className="tablet:hidden w-full">
        <div className="flex flex-col ">
          <div className="">
            <img src={logo} alt="logo" className=" -ml-12 scale-[0.6]" />
          </div>
          <h2 className="">Techsauce Media Co., Ltd.</h2>
          <br />
          <p>
            No. 101, True Digital Park, Griffin
            <br /> Building, Level 14, Unit 01,
            <br />
            Sukhumvit road, Bangchak, Phra
            <br /> Khanong, Bangkok 10260
            <br />
          </p>
          <br />
          <h1 className="text-lg font-bold">Contact us</h1>
          <br />
          <p>
            02-001-5375
            <br /> 06-4658-9500
            <br /> contact@techsauce.co
          </p>
          <div className="flex gap-4 items-center  py-4 pb-8 border-b border-white-pure">
            <a href="https://www.facebook.com/techsauceTH/">
              <img src={facebook} alt="Facebook" />
            </a>
            <a href="https://www.linkedin.com/company/techsauce">
              <img src={twitter} alt="twitter" />
            </a>
            <a href="https://www.linkedin.com/company/techsauce">
              <img src={linkIn} alt="LinkIn" />
            </a>
            <a href="https://www.youtube.com/@TechsauceThailand">
              <img src={youtube} alt="Youtube" />
            </a>
          </div>
          <div className="group p-2 pl-0 pb-4 border-b border-white-pure">
            <div
              className="flex justify-between items-center font-bold"
              onClick={() => {
                if (openMenuName !== "Services") {
                  setOpenMenuName("Services");
                } else {
                  setOpenMenuName("");
                }
              }}
            >
              Services{" "}
              <IconButton
                sx={{
                  marginLeft: "32px",
                  transform:
                    openMenuName === "Services" ? "rotate(180deg)" : "",
                  transition: "400ms",
                }}
                onClick={() => {
                  if (openMenuName !== "Services") {
                    setOpenMenuName("Services");
                  } else {
                    setOpenMenuName("");
                  }
                }}
              >
                <KeyboardArrowDownIcon sx={{ color: "white" }} />
              </IconButton>
            </div>
            <Collapse
              in={openMenuName === "Services"}
              timeout={{
                appear: 600,
                enter: 600,
                exit: 600,
              }}
              orientation="vertical"
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",

                  gap: "12px",
                }}
              >
                <a href="/education#education">Education</a>
                <a href="/education#community">Community & Ecosystem</a>
                <a href="/education#innovation">Innovation Services</a>
                <a href="/education#accelerator">Thailand Accelerator</a>
                <a href="/education#connext">Connext</a>
              </Box>
            </Collapse>
          </div>
          <div className="group p-2 pl-0 pb-4 border-b border-white-pure">
            <div
              className="flex justify-between items-center font-bold"
              onClick={() => {
                if (openMenuName !== "Corporate Services") {
                  setOpenMenuName("Corporate Services");
                } else {
                  setOpenMenuName("");
                }
              }}
            >
              Corporate Services
              <IconButton
                sx={{
                  marginLeft: "32px",
                  transform:
                    openMenuName === "Corporate Services"
                      ? "rotate(180deg)"
                      : "",
                  transition: "400ms",
                }}
                onClick={() => {
                  if (openMenuName !== "Corporate Services") {
                    setOpenMenuName("Corporate Services");
                  } else {
                    setOpenMenuName("");
                  }
                }}
              >
                <KeyboardArrowDownIcon sx={{ color: "white" }} />
              </IconButton>
            </div>
            <Collapse
              in={openMenuName === "Corporate Services"}
              timeout={{
                appear: 600,
                enter: 600,
                exit: 600,
              }}
              orientation="vertical"
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                }}
              >
                <a href="/innovation-services#hackathon">Hackathon</a>
                <a href="/innovation-services#accelerator">Accelerator</a>
                <a href="/innovation-services#venture-building">
                  Venture Building
                </a>
                <a href="/opportunity#business-matching">Business Matching</a>
                <a href="/people#conference-workshop">Conference & Workshop</a>
                <a href="/opportunity#learning-development">
                  Learning & Development
                </a>
                <a href="/people#consultation">
                  Community Building Consultation
                </a>
                <a href="/people#culture-transformation">
                  Culture Transformation
                </a>
                <a href="/people#top-managment-training">
                  Top Management Training
                </a>
                <a href="/people#internal-content">Internal Content</a>
              </Box>
            </Collapse>
          </div>
          <div
            id="linkEducation"
            className="group p-2 pl-0 pb-4 border-b border-white-pure"
          >
            <div
              className="flex justify-between items-center font-bold"
              onClick={() => {
                if (openMenuName !== "Company") {
                  setOpenMenuName("Company");
                } else {
                  setOpenMenuName("");
                }
              }}
            >
              Company
              <IconButton
                sx={{
                  marginLeft: "32px",
                  transform: openMenuName === "Company" ? "rotate(180deg)" : "",
                  transition: "400ms",
                }}
                onClick={() => {
                  if (openMenuName !== "Company") {
                    setOpenMenuName("Company");
                  } else {
                    setOpenMenuName("");
                  }
                }}
              >
                <KeyboardArrowDownIcon sx={{ color: "white" }} />
              </IconButton>
            </div>
            <Collapse
              in={openMenuName === "Company"}
              timeout={{
                appear: 600,
                enter: 600,
                exit: 600,
              }}
              orientation="vertical"
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",

                  gap: "12px",
                }}
              >
                <a href="/">Home</a>
                {/* <a href="/">Upcoming Event</a> */}
                <a href="/blogs">Blogs</a>
                <a href="/about-us">About us</a>
                <a href="https://techsauce.co/">Techsauce Media</a>
              </Box>
            </Collapse>
          </div>
          <div id="linkEducation" className="group p-2 pl-0 ">
            <div
              className="flex justify-between items-center font-bold"
              onClick={() => {
                if (openMenuName !== "Resources") {
                  setOpenMenuName("Resources");
                } else {
                  setOpenMenuName("");
                }
              }}
            >
              Resources
              <IconButton
                sx={{
                  marginLeft: "32px",
                  transform:
                    openMenuName === "Resources" ? "rotate(180deg)" : "",
                  transition: "400ms",
                }}
                onClick={() => {
                  if (openMenuName !== "Resources") {
                    setOpenMenuName("Resources");
                  } else {
                    setOpenMenuName("");
                  }
                }}
              >
                <KeyboardArrowDownIcon sx={{ color: "white" }} />
              </IconButton>
            </div>
            <Collapse
              in={openMenuName === "Resources"}
              timeout={{
                appear: 600,
                enter: 600,
                exit: 600,
              }}
              orientation="vertical"
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                }}
              >
                <a href="https://startupdirectory.techsauce.co/">
                  Startup Directory
                </a>
              </Box>
            </Collapse>
          </div>
        </div>
      </div>
      {/* desktop */}
      <div className="hidden tablet:grid grid-cols-4 gap-6 ml-10 laptop:ml-24 desktop:ml-32 max-w-[1280px]">
        <div className="flex flex-col">
          <div className="flex justify-start">
            <img src={logo} alt="logo" className=" h-[30px] w-[145px]" />
          </div>
          <h2 className="mt-1">Techsauce Media Co., Ltd.</h2>
          <br />

          <p>
            No. 101, True Digital Park, Griffin
            <br /> Building, Level 14, Unit 01,
            <br />
            Sukhumvit road, Bangchak, Phra
            <br /> Khanong, Bangkok 10260
            <br />
          </p>
          <br />
          <h1 className="text-xl font-bold">Contact us</h1>
          <br />
          <p>
            02-001-5375
            <br /> 06-4658-9500
            <br /> contact@techsauce.co
          </p>
        </div>
        <div className="flex flex-col">
          <h1 className="text-xl font-bold ">Services</h1>
          <a href="/education#education" className="p-2 pl-0">
            Education
          </a>
          <a href="/education#community" className="p-2 pl-0">
            Community & Ecosystem
          </a>
          <a href="/education#innovation" className="p-2 pl-0">
            Innovation Services
          </a>
          <a href="/education#accelerator" className="p-2 pl-0">
            Thailand Accelerator
          </a>
          <a href="/education#connext" className="p-2 pl-0">
            Connext
          </a>
        </div>
        <div className="flex flex-col">
          <h1 className="text-xl font-bold ">Corporate services</h1>
          <a href="/innovation-services#hackathon" className="p-2 pl-0">
            Hackathon
          </a>
          <a href="/innovation-services#accelerator" className="p-2 pl-0">
            Accelerator
          </a>
          <a href="/innovation-services#venture-building" className="p-2 pl-0">
            Venture Building
          </a>
          <a href="/opportunity#business-matching" className="p-2 pl-0">
            Business Matching
          </a>
          <a href="/people#conference-workshop" className="p-2 pl-0">
            Conference & Workshop
          </a>
          <a href="/opportunity#learning-development" className="p-2 pl-0">
            Learning & Development
          </a>
          <a href="/people#consultation" className="p-2 pl-0">
            Community Building Consultation
          </a>
          <a href="/people#culture-transformation" className="p-2 pl-0">
            Culture Transformation
          </a>
          <a href="/people#top-managment-training" className="p-2 pl-0">
            Top Management Training
          </a>
          <a href="/people#internal-content" className="p-2 pl-0">
            Internal Content
          </a>
        </div>
        <div className="flex flex-col">
          <h1 className="text-xl font-bold ">Company</h1>
          <a href="/" className="p-2 pl-0">
            Home
          </a>
          <a href="/blogs" className="p-2 pl-0">
            Blogs
          </a>
          <a href="/about-us" className="p-2 pl-0">
            About us
          </a>
          <a href="https://techsauce.co/" className="p-2 pl-0">
            Techsauce Media
          </a>
          <h1 className="text-xl font-bold mt-4">Resources</h1>
          <Link
            to="https://startupdirectory.techsauce.co/"
            className="p-2 pl-0"
          >
            Startup Directory
          </Link>
        </div>
      </div>
    </footer>
  );
}
